<template>
  <PageListLayout
    icon="mdi-folder-account"
    title="Danh sách khách hàng"
    subTitle="Danh sách khách hàng"
    titleAdd="Tạo mới khách hàng"
    :useAdd="createCustomer"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="side-right">
      <div class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-1" icon v-on="on" @click="onMergeCustomer()">
              <v-icon> mdi-call-merge </v-icon>
            </v-btn>
          </template>
          <span>Gộp khách hàng </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-1" icon v-on="on" @click="importData()">
              <v-icon> mdi-import </v-icon>
            </v-btn>
          </template>
          <span>Import dữ liệu</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :loading="loadingExport"
              class="mr-1"
              icon
              v-on="on"
              @click="exportCustomerApi"
            >
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Kết xuất dữ liệu</span>
        </v-tooltip>
      </div>
    </template>
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <!-- <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.area_id"
          :items="areasData"
          label="khu vực"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div> -->
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.type_customer_id"
          :items="customerTypesData"
          label="Nhóm khách hàng"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.person_in_charge_id"
          :items="employers"
          label="Nhân viên phụ trách"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-btn
          color="error"
          dark
          large
          @click="deleted = !deleted"
          v-if="deleted == false"
        >
          Khách hàng dừng hoạt động
        </v-btn>
        <v-btn
          color="success"
          dark
          large
          @click="deleted = !deleted"
          v-if="deleted == true"
        >
          Khách hàng đang hoạt động
        </v-btn>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div
          class="d-flex align-center pointer"
          @click="editItem(item)"
          :title="item.name"
        >
          <div class="style--ellipsis">{{ item.name }}</div>
        </div>
      </template>
      <!-- <template v-slot:[`item.area_id`]="{ item }">
        <span>{{ getAreaName(item.area_id) }}</span>
      </template> -->

      <template v-slot:[`item.type`]="{ item }">
        <span
          :class="
            `badge ${getCustomerTypesName(item.type) &&
              styleStatus[handleColorType(getCustomerTypesName(item.type))]}`
          "
          >{{ getCustomerTypesName(item.type) }}</span
        >
      </template>

      <template v-slot:[`item.deleted`]="{ item }">
        <span v-if="item.deleted" class="badge error"> Không hoạt động </span>
        <span v-else class="badge success"> Hoạt động </span>
      </template>

      <template v-slot:[`item.contracts`]="{ item }">
        <span v-if="item.contracts.length > 0" class="badge error"
          >Đã có {{ item.contracts.length }} Hợp đồng</span
        >
        <span v-else class="badge success">Chưa có hợp đồng</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>

            <v-list-item @click="updateAction(item)" v-if="deleted == false">
              <v-list-item-title>
                <v-icon left>mdi-cancel </v-icon>
                Dừng hoạt động
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="custommerAction(item)" v-if="deleted == true">
              <v-list-item-title>
                <v-icon left> mdi-checkbox-marked-circle </v-icon>
                Khách hàng hoạt động
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit
      ref="form"
      @on-done="getAllList"
      :AreasList="[]"
      :CustomerTypesList="customerTypesData"
    ></create-edit>
    <ImportFile ref="formImport" @on-done="getAllList"></ImportFile>
    <MergeCustomer
      ref="formMerge"
      :listCustomer="listCustomer"
      @on-done="getAllList"
    ></MergeCustomer>
  </PageListLayout>
</template>
<script>
import KHACHHANG from "@/api/khachhang";
import { getAllCustomerTypes } from "@/api/loaikhachhang";
import { getAllAreas } from "@/api/khuvuc";
import CreateEdit from "./create-edit";
import ImportFile from "./import-file";
import MergeCustomer from "./merge-customer";
import { debounce } from "lodash";
import _ from "lodash";
import styleStatus from "@/utils/StatusColorHandle";
import { saveAs } from "file-saver";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import state from "@/store/modules/user.js";
export default {
  components: { CreateEdit, ImportFile, MergeCustomer },
  data() {
    return {
      formSearch: {},
      createCustomer: false,
      listCustomer: [],
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      customerTypesData: [],
      deleted: false,
      customerDeletedData: [],
      // areasData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      headers: [
        // { text: "STT", width: "100", sortable: false },
        // { text: "Icon", value: "icon", sortable: false },
        { text: "Tên khách hàng", value: "name" },
        { text: "Mã ID", value: "id" },
        {
          text: "Mã khách hàng",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Người phụ trách",
          value: "person_in_charge.name",
          width: "100px",
        },
        { text: "Mã misa phế liệu", value: "misa_code_pl" },
        { text: "Mã misa chất thải", value: "misa_code_ct" },
        // { text: "Email", value: "email" },
        { text: "Số điện thoại", value: "phone" },
        // { text: "Địa chỉ", value: "address" },
        // { text: "Fax", value: "fax" },
        // { text: "Mã số thuế", value: "tax" },
        { text: "Tên người đại diện", value: "agent_name" },
        // { text: "Chức vụ người đại diện", value: "agent_position" },
        { text: "Nhóm khách hàng", value: "type" },
        { text: "Trạng thái hợp đồng", value: "contracts" },

        { text: "Trạng thái", value: "deleted" },

        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
      json_fields: {},
      styleStatus,
      loadingExport: false,
      employers: [],
    };
  },
  computed: {
    dataOnExport() {
      let dataexport = [];

      dataexport = _.cloneDeep(this.tableData);

      for (const i in dataexport) {
        if (dataexport[i].type !== "") {
          dataexport[i].type = this.customerTypesData.find(
            (item) => item.id === dataexport[i].type
          )?.name;
        }
      }

      return dataexport;
    },
  },
  created() {
    this.loading = true;
    Promise.all([
      this.getAllListCustomer(),
      this.getAllList(),
      this.getAllCustomerTypes(),
      this.getAllEmployee(),
      this.setRoleCreate(),
    ]).finally(() => {
      this.loading = false;
    });
    for (let i in this.headers) {
      this.json_fields[this.headers[i].text] = this.headers[i].value;
    }
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
    deleted: function() {
      this.loading = true;
      this.getAllList();
      this.loading = false;
    },
  },
  methods: {
    setRoleCreate() {
      //giapthihien@email.com
      if (this.$store.state.User.me.email == "giapthihien@email.com") {
        this.createCustomer = true;
      }
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getAllListCustomer() {
      let data = await KHACHHANG.list({
        pagination: false,
      });
      this.listCustomer = data.data;
    },

    async getAllList() {
      let data = await KHACHHANG.list({
        page: this.page,
        perPage: this.itemsPerPage,
        ...this.formSearch,
        deleted: this.deleted,
      });
      this.tableData = data.data.data;
      this.tableData[0].birthday = true;
      this.pageCount = data.data.last_page;
    },
    // async getAllArea() {
    //   let data = await getAllAreas();
    //   this.areasData = data;
    // },
    async getAllCustomerTypes() {
      let data = await getAllCustomerTypes();
      this.customerTypesData = data;
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$router.push({
        path: `/quanlykhachhang/chitietkhachhang/${item.id}`,
      });
      // this.$refs.form.showFormEdit(item);
    },
    async updateAction(item) {
      this.$confirmBox.show({
        title: "Dừng hoạt động",
        width: 500,
        body:
          "Bạn có chắc chắn muốn dừng hoạt động khách hàng" +
          "<strong>" +
          item.name +
          " ?" +
          "</strong>",
        action: () =>
          KHACHHANG.updateActiveCustomers({
            id: item.id,
            deleted: true,
            active: false,
          }),
        onDone: this.getAllList,
      });
    },
    async custommerAction(item) {
      this.$confirmBox.show({
        title: "Mở lại hoạt động khách hàng",
        width: 500,
        body:
          "Bạn có chắc chắn muốn mở lại hoạt động khách hàng" +
          "<strong>" +
          item.name +
          " ?" +
          "</strong>",
        action: () =>
          KHACHHANG.custommerAction({
            id: item.id,
            deleted: false,
            active: true,
          }),
        onDone: this.getAllList,
      });
    },
    createItem() {
      // this.$refs.form.showFormAdd();
      this.$router.push({
        path: `/quanlykhachhang/themmoikhachhang`,
      });
    },
    importData() {
      this.$refs.formImport.showFormImport();
    },
    onMergeCustomer() {
      this.$refs.formMerge.showFormImport();
    },
    getAreaName(id) {
      return this.areasData.find((item) => item.id === id)
        ? this.areasData.find((item) => item.id === id).name
        : null;
    },
    getCustomerTypesName(id) {
      return this.customerTypesData.find((item) => item.id === id)
        ? this.customerTypesData.find((item) => item.id === id).name
        : null;
    },
    getCustomerStatusName(id) {
      let deleted = this.customerTypesData.find((item) => item.id === id)
        ? this.customerTypesData.find((item) => item.id === id).deleted
        : null;
      return console.log(deleted);
    },
    async exportCustomerApi() {
      try {
        this.loadingExport = true;
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        let newdate = day + "/" + month + "/" + year;
        this.formSearch.deleted = this.deleted;
        const res = await KHACHHANG.exportCustomer(this.formSearch);
        if (res && res.isDone) {
          saveAs(new Blob([res.data]), "Khach_hang" + "-" + newdate + ".xlsx");
        }
      } finally {
        this.loadingExport = false;
      }
    },
    handleColorType(name) {
      let r = 0;
      if (name == "Khác") {
        r = 4;
      } else if (name == "Khách hàng thường") {
        r = 2;
      } else if (name == "Khách hàng trọng tâm") {
        r = 3;
      }
      return r;
    },
  },
};
</script>
<style scoped>
.header__right__list_item {
  min-height: 40px !important;
  height: 40px !important;
}
/* /deep/ .v-tab:not(.v-tab--active){
  color:#000000e3 !important;
} */
.tab_color {
  color: #000;
}
.style--ellipsis {
  max-width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
