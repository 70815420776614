<template>
  <v-dialog v-model="show" persistent width="1200">
    <v-card :loading="isLoading">
      <v-card-title class="headline">{{ "IMPORT KHÁCH HÀNG" }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <div class="label-form">File import</div>
              <input
                ref="files"
                name="files"
                type="file"
                @change="handleUpload"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <!--   
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import KHACHHANG from "@/api/khachhang";
import XLSX from "xlsx";
export default {
  data: () => ({
    isLoading: false,
    show: false,
    importData: {
      data: [],
    },
  }),
  computed: {},
  methods: {
    showFormImport() {
      this.show = true;
      if (this.$refs["files"]) {
        this.$refs["files"].value = null;
      }
    },
    handleUpload(e) {
      let files = e.target.files;
      var filePath = files[0].name.split(".").pop(); //lấy định dạng file
      var dinhDangChoPhep = ["xlsx", "xls"]; //các tập tin cho phép
      const isLt2M = files[0].size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.form.fileList.pop();
        this.$toast.warning("Kích thước file ảnh tối đa 20Mb!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return false;
      }
      if (!dinhDangChoPhep.find((el) => el == filePath.toLowerCase())) {
        this.loadingUpload = false;
        this.listLoading = false;
        this.iconUpload = "el-icon-bottom";
        this.$toast.warning("Tập tin không hợp lệ!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }

      // this.$refs.form.validate();

      // if (this.$refs.form.validate()) {
      const fileReader = new FileReader(); // construction function that can read the file content
      fileReader.onload = (ev) => {
        const data = ev.target.result;
        const workbook = XLSX.read(data, {
          type: "binary", // binary
        });

        const wsname = workbook.SheetNames[0]; //take the first sheet

        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //Get the data in this table

        this.importData.data = ws;

        this.importCustomers(this.importData).catch(() => {
          this.isLoading = false;
          this.$refs["files"].value = null;
          this.show = false;
        });
      };
      fileReader.readAsBinaryString(files[0]); // read file, trigger onload
    },

    async importCustomers(data) {
      this.isLoading = true;
      await KHACHHANG.importCustomers(data).then(res=> console.log(res));

      this.$emit("on-done");
      this.$toast.info("Import thành công", {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
      this.isLoading = false;
      this.$refs["files"].value = null;
      this.show = false;
    },
  },
};
</script>
