<template>
  <v-dialog v-model="show" persistent width="800">
    <v-card :loading="isLoading">
      <v-card-title class="headline">{{ "GỘP KHÁCH HÀNG" }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Chọn khách hàng</div>
              <v-autocomplete
                v-model="form.selectedCustomer"
                placeholder="Chọn công ty"
                :items="listCustomer"
                outlined
                item-text="name"
                item-value="id"
                dense
                return-object
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Chọn khách hàng gộp</div>
              <v-autocomplete
                v-model="form.selectedOtherCustomer"
                placeholder="Chọn khách hàng gộp"
                :items="listCustomer"
                outlined
                item-text="name"
                item-value="id"
                dense
                multiple
                return-object
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <div>
                <ul style="text-decoration: none;">
                  <li>
                    <p>Mã khách hàng :{{ form.selectedCustomer.code }}</p>
                  </li>
                  <li>
                    <p>Tên khách hàng :{{ form.selectedCustomer.name }}</p>
                  </li>
                  <li>
                    <p>
                      Email khách hàng :{{
                        form.selectedCustomer.email
                          ? form.selectedCustomer.email
                          : "Chưa có"
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <p>Danh sách khách hàng chọn để gộp</p>
                <ul style="text-decoration: none;">
                  <li v-for="item in form.selectedOtherCustomer" :key="item.id">
                    <p>{{ item.name }}</p>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <!--   
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>-->
        <v-btn :loading="isLoading" color="primary" @click="mergeCustomer">
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import KHACHHANG from "@/api/khachhang";

export default {
  props: {
    listCustomer: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: false,
    show: false,
    form: {
      selectedCustomer: {},
      selectedOtherCustomer: [],
    },
  }),
  computed: {},
  methods: {
    showFormImport() {
      this.show = true;
      this.form = {
        selectedCustomer: {},
        selectedOtherCustomer: [],
      };
    },
    getChangeNeedId() {
      const changeNeedIds = this.form.selectedOtherCustomer.map(
        (item) => item.id
      );
      return changeNeedIds;
    },
    async mergeCustomer() {
      this.isLoading = true;

      let data = {
        target_id: this.form.selectedCustomer.id,
        change_needed_ids: this.getChangeNeedId(),
      };
      // console.log(data, 3333);
      try {
        const res = await KHACHHANG.mergeCustomers(data);

        if (!res.error) {
          this.$emit("on-done");
          this.isLoading = false;
          this.show = false;
        }
      } catch (error) {
        this.errorNotify("Lỗi gộp");
        this.show = true;
      }
    },
    errorNotify(message) {
      this.$toast.error(message, {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
