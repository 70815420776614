<template>
  <v-dialog v-model="show" persistent width="1200">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT KHÁCH HÀNG" : "THÊM KHÁCH HÀNG"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Mã khách hàng</div>
              <v-text-field
                v-model="form.code"
                placeholder="Mã khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="codeRules"
                :disabled="edit ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Mã Misa</div>
              <v-text-field
                v-model="form.misa_code"
                placeholder="Mã Misa"
                outlined
                dense
                :rules="misaCodeRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div class="label-form">Tên khách hàng</div>
              <v-text-field
                v-model="form.name"
                placeholder="Tên khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Email</div>
              <v-text-field
                v-model="form.email"
                placeholder="Email khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div class="label-form">Số điện thoại</div>
              <v-text-field
                v-model="form.phone"
                placeholder="Số điện thoại"
                outlined
                dense
                prepend-inner-icon="mdi-cellphone"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Fax</div>
              <v-text-field
                v-model="form.fax"
                placeholder="Fax"
                outlined
                dense
                prepend-inner-icon="mdi-fax"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Địa chỉ</div>
              <v-text-field
                v-model="form.address"
                placeholder="Địa chỉ"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Mã số thuế</div>
              <v-text-field
                v-model="form.tax"
                placeholder="Mã số thuế"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div class="label-form">Người đại diện</div>
              <v-text-field
                v-model="form.agent_name"
                placeholder="Người đại diện"
                outlined
                dense
                prepend-inner-icon="mdi-account-network"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Chức vụ người đại diện</div>
              <v-text-field
                v-model="form.agent_position"
                placeholder="Chức vụ người đại diện"
                outlined
                dense
                prepend-inner-icon="mdi-menu-down"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div class="label-form">Khu vực</div>
              <v-select
                prepend-inner-icon="mdi-map-marker"
                v-model="form.area_id"
                :items="AreasList"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Nhóm khách hàng</div>
              <v-select
                prepend-inner-icon="mdi-account-group"
                v-model="form.type"
                :items="CustomerTypesList"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import KHACHHANG from "@/api/khachhang";

export default {
  props: ["AreasList", "CustomerTypesList"],
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      code: null,
      misa_code: null,
      name: null,
      email: null,
      phone: null,
      address: null,
      add_product: null,
      fax: null,
      tax: null,
      agent_name: null,
      agent_position: null,
      area_id: null,
      type: null,
      description: null,
      product_adds: null,
    },
    nameRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    misaCodeRules: [(v) => !!v || "Mã misa không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã khách hàng không thể bỏ trống"],
    emailRules: [
      (v) => !!v || "E-Mail không thể bỏ trống",
      (v) => /.+@.+\..+/.test(v) || "E-mail không hợp lệ",
    ],
  }),
  computed: {},
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = this.$options.data.call(this).form;
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        await KHACHHANG.create(this.form);
        this.show = false;
        this.btnLoading = false;

        this.$emit("on-done");
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        await KHACHHANG.update(this.form.id, this.form);
        this.show = false;
        this.btnLoading = false;
        this.$emit("on-done");
      }
    },
  },
};
</script>
